async function importFlickity() {
    const { default: Flickity } = await import(/* webpackChunkName: "flickity" */ 'flickity');
    return Flickity;
}

importFlickity().then((Flickity) => {

    // call to update hidden slide aria attributes
    function setHeroSlide (hiddenSlides) {
        hiddenSlides.forEach((hiddenSlide) => {
            hiddenSlide.setAttribute('tabindex', '-1');
        });
    }

    // init flickity slider
    const carousel = document.querySelector('.recipes-carousel');
    const recipeCarousel = new Flickity(carousel, {
        // flickity event binding
        // https://flickity.metafizzy.co/events.html#ready
        on: {
            ready: () => {
                let hiddenSlides = document.querySelectorAll(".recipe-cell[aria-hidden='true']");
                setHeroSlide(hiddenSlides);
            }
        },
        accessibility: false,
        adaptiveHeight: true,
        cellAlign: 'left',
        draggable: '>1',
        freeScroll: true,
        pageDots: false,
        prevNextButtons: false,
        setGallerySize: false
    });

    // recipe carousel button event listeners
    let previousButton = document.querySelector('.recipe-next-prev.previous');
    if (previousButton) {
        previousButton.addEventListener('click', function () {
            recipeCarousel.previous();
        });
    }
    let nextButton = document.querySelector('.recipe-next-prev.next');
    if (nextButton) {
        nextButton.addEventListener('click', function () {
            recipeCarousel.next();
        });
    }
});